import "./App.css";
import "../src/assets/css/assets.css";
import "../src/assets/css/style.css";
// import Home from "../src/components/Home";
import { Route, Routes, useLocation } from "react-router-dom";
// import CourseDetails from "./components/Courses/CourseDetails";
// import Header from "./components/Home/Header";
// import MainContactUs from "./components/MainContactUs/MainContactUs";
import { useEffect, lazy, Suspense  } from "react";
// import Footer from "./components/Home/Footer";
// import PrivacyPolicy from "./components/PrivacyPolicy";
// import TermsCondition from "./components/TermsCondition";
// import AboutUs from "./components/AboutUs";
import whatApp from "../src/assets/images/newwhatAppIcon.png";
import { HelmetProvider } from "react-helmet-async";
import Loader from "./components/Loader";



// Lazy-loaded components
const Home = lazy(() => import("../src/components/Home"));
const CourseDetails = lazy(() => import("./components/Courses/CourseDetails"));
const Header = lazy(() => import("./components/Home/Header"));
const MainContactUs = lazy(() => import("./components/MainContactUs/MainContactUs"));
const Footer = lazy(() => import("./components/Home/Footer"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const TermsCondition = lazy(() => import("./components/TermsCondition"));
const AboutUs = lazy(() => import("./components/AboutUs"));

function App() {
  let location = useLocation();
  console.log(location.pathname);

  
  // Dynamically load the Google Analytics script
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://www.googletagmanager.com/gtag/js?id=G-WLZYHEEE13";
  //   script.async = true;
  //   document.head.appendChild(script);

  //   script.onload = () => {
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag() {
  //       window.dataLayer.push(arguments);
  //     }
  //     gtag("js", new Date());
  //     gtag("config", "G-WLZYHEEE13");

  //     // Log the first page view
  //     gtag("event", "page_view", { page_path: location.pathname });
  //   };
  // }, []);


    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-WLZYHEEE13";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
          window.dataLayer.push(arguments);
        };
        window.gtag("js", new Date());
        window.gtag("config", "G-WLZYHEEE13");

        // Log the initial page view
        window.gtag("event", "page_view", { page_path: location.pathname });
      };
    }, []);

  // Track page views on route change
  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "page_view", { page_path: location.pathname });
    }
  }, [location.pathname]);



  const openWhatsAppChat = () => {
    const phoneNumber = "9926640515";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  return (
    <HelmetProvider>
      <Suspense fallback={<Loader/>}>
        <div id="bg">
          {location.pathname === "/" ? null : <Header />}

          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/IT-training-course-indore"
              element={<CourseDetails />}
            />
            <Route path="/Contact-Us" element={<MainContactUs />} />
            <Route path="/Price&Policy" element={<PrivacyPolicy />} />
            <Route path="/Terms&Conditions" element={<TermsCondition />} />
            <Route path="/About-us" element={<AboutUs />} />
          </Routes>
          <Footer />
          <div className="chatBot" onClick={openWhatsAppChat}>
            <img src={whatApp} alt="chatBot" />
          </div>
        </div>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
