import React from "react";
import "../App.css"
import loaderIcon from "../assets/images/loading-02.svg";

const Loader = () => (
  <div className="loader">
    <img src={loaderIcon} alt="Loading..." className="loader-icon" />
    <p>Loading...</p>
  </div>
);

export default Loader;
